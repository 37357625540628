@import "src/scss/common.scss";
@import "src/scss/mixins.scss";

html,
body {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

body {
  background: #010101;
  font-family: "Roboto Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  position: relative;

  &::before {
    background: url("./assets/images/background.jpg") 65% 0 no-repeat;
    background-size: auto 100%;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.8;
    pointer-events: none;
    position: absolute;
    top: -20%;
    width: 100%;

    @include tablet() {
      background-position: center;
      background-size: cover;
      opacity: 1;
      top: 0;
    }
  }
}

#root {
  align-items: start;
  display: grid;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  &::before {
    background: url("./assets/images/mafia.png") 40% center no-repeat;
    background-size: auto 100%;
    bottom: 0;
    content: "";
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 42.83%;
    width: 100%;

    @include mobile() {
      background-position: 0 100%;
    }

    @include tablet() {
      top: 22.83%;
    }
  }
}
