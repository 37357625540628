$mobile-width: 360px;
$tablet-width: 768px;
$laptop-width: 1024px;

@mixin mobile {
  @media (min-width: $mobile-width) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-width) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: $laptop-width) {
    @content;
  }
}
