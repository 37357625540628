@import "src/scss/mixins.scss";

.app {
  align-items: center;
  display: grid;
  height: 22.83%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  @include tablet() {
    align-items: end;
  }
}

.note {
  color: #adac95;
  font-family: "Playfair Display", serif;
  font-weight: 700;
  gap: 12px;
  mix-blend-mode: luminosity;
  padding: 1% 4.2%;
  text-align: center;
  text-shadow: -39.7px 39.7px 61.76px rgba(0, 0, 0, 0.2),
    -26.47px 26.47px 26.47px rgba(0, 0, 0, 0.2), -8.82px 8.82px 8.82px rgba(0, 0, 0, 0.25),
    7.06px -7.06px 8.82px rgba(0, 0, 0, 0.07);

  @include tablet() {
    text-align: right;
    text-shadow: -45px 45px 70px rgba(0, 0, 0, 0.2), -30px 30px 30px rgba(0, 0, 0, 0.2),
      -10px 10px 10px rgba(0, 0, 0, 0.25), 8px -8px 10px rgba(0, 0, 0, 0.07);
  }
}

.note__text,
.note__link {
  font-size: 35px;
  line-height: 47px;

  @include tablet() {
    font-size: 60px;
    line-height: 80px;
  }
}

.note__text {
  font-size: 7vmin;
  line-height: 1.3;

  /*
  @include laptop() {
    font-size: 80px;
    line-height: 107px;
  }
  */
}

.note__link {
  color: #fae5b1;
  font-size: 9vmin;
  line-height: 1.2;
  padding-left: 8px;
  text-decoration: underline;
  white-space: nowrap;

  /*
  @include laptop() {
    font-size: 110px;
    line-height: 133px;
  }
  */
}
